@import "./utils/index.scss";
/* theme color */
$--color-primary: #060181;
/* icon font path, required */
$--font-path: '~element-ui/lib/theme-chalk/fonts';
@import "~element-ui/packages/theme-chalk/src/index";

.min-height-table {
    min-height: 180px;
}

.el-dialog__wrapper{
  .with-border{
    border-bottom: 1px solid #e5e5e5;
  }
}

.ck-editor__editable {
  min-height: 120px;
}

.el-form-item__label {
  font-weight: bold;
  padding: 0!important;
}
