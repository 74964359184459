//
// Backgrounds
//


@each $color, $value in $colors {
	@include bg-variant(".bg-#{$color}", $value);
}

// Shape (svg) fill colors

@each $color, $value in $theme-colors {
    .fill-#{$color}  {
        fill: $value;
    }

    .stroke-#{$color}  {
        stroke: $value;
    }
}

.fill-opacity-8 {
	fill-opacity: .8;
}
