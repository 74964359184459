// Core

@import "./custom/functions";
@import "../../sass/global/variables";
@import "./custom/variables";
@import "./custom/mixins";

// bootstrap (4.3.1) components

@import "./bootstrap/root";
@import "./bootstrap/reboot";
@import "./bootstrap/utilities";
@import "./bootstrap/print"; 

// Argon utilities and components

@import "./custom/utilities";

.btn:hover{
    box-shadow: none;
}

.btn{
    font-weight: inherit;
}

body{
    background-color: white;
}

.pointer-on-hover {
    cursor: pointer;
}
